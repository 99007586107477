import { ReactNode } from 'react';

export type NotFoundProps = {
	children?: ReactNode;
};

const NotFound = (_props: NotFoundProps) => {
	return (
		<div>
			<h1>Page NotFound</h1>
		</div>
	);
};
export default NotFound;
